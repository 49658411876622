const VideoData = [
    {
        id: 0,
        index: 0,
        title: 'Rotten Town Live at the PinChurch',
        author: 'Mystic Krewe',
        url: 'https://www.youtube.com/embed/G4KRmIBG1Gk',
    },
    {
        id: 1,
        index: 1,
        title: 'OnBeat Sessions',
        author: 'Offbeat Magazine',
        url: 'https://www.youtube.com/embed/bf6I9-USJ1A',
    },
    {
        id: 2,
        index: 2,
        title: 'OnBeat Sessions',
        author: 'Offbeat Magazine',
        url: 'https://www.youtube.com/embed/bf6I9-USJ1A',
    },
    {
        id: 3,
        index: 3,
        title: 'OnBeat Sessions',
        author: 'Offbeat Magazine',
        url: 'https://www.youtube.com/embed/bf6I9-USJ1A',
    }
]

export default VideoData